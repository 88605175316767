import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, Button, Paper } from '@mui/material';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Attic - Secure Social Media by Haridas Maurya</title>
        <meta name="description" content="Discover Attic, the social media app with end-to-end encryption chat and unique capsule features, founded by Haridas Maurya." />
        <meta name="keywords" content="Haridas Maurya, Attic, social media, end-to-end encryption, capsule feature, secure chat" />
      </Helmet>
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Box textAlign="center">
            <Typography variant="h3" component="h1" gutterBottom>
              Attic Social Media App
            </Typography>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Secure, Innovative, and Privacy-Focused Social Connections
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="body1" paragraph>
              <strong>Founder:</strong> Haridas Maurya, a visionary innovator and student at United University,
              has combined his passion for technology and social connectivity to create Attic – a platform
              that prioritizes user privacy and introduces creative features such as location-based and time-based capsules.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Key Features:</strong>
            </Typography>
            <ul>
              <li>End-to-End Encryption Chat – Your conversations remain private and secure.</li>
              <li>India’s First – Pioneering encrypted chat in the country.</li>
              <li>Capsule Feature – Create and discover location-based and time-based capsules.</li>
            </ul>
            <Box mt={3} textAlign="center">
              <Button variant="contained" color="primary" size="large" href="https://opentruths.com" target="_blank">
                Learn More
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Home;
